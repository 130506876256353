import micro_modal from 'micromodal';
import { Calendar } from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import onmount from 'onmount';
import Rails from '@rails/ujs';
import Tagify from '@yaireo/tagify'
import Cookies from 'js-cookie';

var lodash_isEmpty = require('lodash/isEmpty');
var lodash_size = require('lodash/size');
var lodash_isEqual = require('lodash/isEqual');

import { blUnsavedWarning, blConfirmation, capitalizeFirstLetter, setAsLoading, getModalType } from 'helpers.js';

var calendar = null;
var deleted_dates = {};
var deleted_playthroughs = [];
var log_data = {};
var playthroughs = {};
var all_changes = {};
var check_for_unsaved_changes = true;
var all_changes_needs_update = true; // Used for making sure all_changes only updates once when modal is opened
var tagify = null;
var most_recent_tags = [];
var block_esc_key = false; // Tracks when to disable esc key when opening modals
var new_library_entry_id = -2; // Tracks creation of new library entries in the log editor UI
var library_entry_promise = null; // Promise used to track when library entries are finished loading to save data for unsaved changes

async function update_all_changes() {
	if (library_entry_promise) {
		await library_entry_promise;
		library_entry_promise = null;
	}

	if (all_changes_needs_update) {
    all_changes = getAllData();
    all_changes_needs_update = false;
    check_for_unsaved_changes = true;
	}
}

function createPlaythrough(new_id) {
	return { 
		id: new_id, 
		created_at: null, 
		title: new_id < -1 ? "Log " + -new_id : "Log", 
		rating: log_data ? log_data.rating : 0 
	}
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function formatDate(date) {
	return date.toLocaleDateString('en-US', {
		month: 'short',
		day: 'numeric'
	});
}

function formatFullDate(date, alt=false) {
	const dd = String(date.getDate()).padStart(2, '0');
	const mm = String(date.getMonth() + 1).padStart(2, '0');
	const yyyy = date.getFullYear();

	if (alt) {
		return yyyy + "-" + mm + "-" + dd;
	}
	return date.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'short',
		day: 'numeric'
	});
}

function resetStartEvent(event_id) {
	let start_event = calendar.getEventById(event_id);
	if(start_event != null) {
		start_event.setExtendedProp('start_date', null);
		$('[data-date]').removeClass('start-tag');
		start_event.setExtendedProp('edited', true);
	}
}

function getEarliestEvent() {
	if (calendar == null) {
		return null;
	}

	var earliest = null;

	$.each(calendar.getEvents(), function(index, event) {

		if(earliest == null || new Date(event.start) < new Date(earliest.start)) {
			earliest = event;
		}
	});

	return earliest;
}

function getLatestDate(events) {
	var latest = null;
	$.each(events, function(index, event) {
		if(latest == null || new Date(event.end) > new Date(latest)) {
			latest = new Date(event.end)
		}
	});

	if(latest == null) {
		latest = new Date();
	} else {
		latest = new Date(latest);
	}

	return latest;
}

function openPlayDate(event) {
	const data = event.extendedProps;
	const start_date = new Date($('#started-on-fillin').attr('date'));
	const finish_date = new Date($('#finished-on-fillin').attr('date'));

	$.ajax({
		type: "POST",
		url: "/open-date-modal/",
		data: { play_data: { 
			privacy: data.privacy, 
			note: data.note, 
			status: data.status, 
			id: event.id,
			hours: data.hours, 
			finish_date: data.finish_date,
			start_date: data.start_date,
			minutes: data.minutes, 
			start: formatDate(event.start), 
			end: formatDate(addDays(event.end, -1)),
			tags: data.tags,
			disableStart: finish_date < event.start,
			disableFinish: start_date > addDays(event.end, -1)
		}},
		success: function(result) {
			$('#playthrough-modal-content').html(result);
			onmount();
			block_esc_key = false;
			let tagElement = document.querySelector('#play-session-tags');
			tagify = new Tagify(tagElement, {
				maxTags: 8,
				editTags: false,
				pattern: /^[\w, -]{1,24}$/,
				transformTag: function(tagData) {
					tagData.value = tagData.value.toLowerCase();
				},
				callbacks: {
					"input": function(e) {
						if (e.detail.isValid == 'pattern mismatch' && e.detail.value[e.detail.value.length - 1] != ',') {
							$('#tags-char-warning').removeClass('d-none');
							$('.backloggd-tags').addClass('over-char-warning');
						} else {
							$('#tags-char-warning').addClass('d-none');
							$('.backloggd-tags').removeClass('over-char-warning');
						}
					},
					"invalid": function(e) {
						$('#tags-char-warning').addClass('d-none');
						$('.backloggd-tags').removeClass('over-char-warning');
					}
				},
				whitelist: most_recent_tags,
				dropdown : {
					classname: "tagify-dropdown",
					enabled: 0,
					maxItems: 3,
					position: "text",
					closeOnSelect : false,
				}
			});

			tagify.addTags(data.tags);

			micro_modal.show('playthrough-modal', {
				awaitOpenAnimation: true,
				awaitCloseAnimation: true,
				onClose: modal => { block_esc_key = true; }
			});
		}
	});
}

function createStartEvent(start_date) {
	if (typeof start_date == "string") {
		start_date = new Date(start_date);
	}

	const event_id = -(calendar.getEvents().length + 1);
	let existing_event = getEventAtDate(start_date);

	if (existing_event == null) {
		existing_event = calendar.addEvent({
    	  id: event_id,
          title: "Played",
          start: start_date,
          end: addDays(start_date, 1),
          backgroundColor: '#ea377a',
          borderColor: '#ea377a',
          allDay: true,
          extendedProps: {
          	start_date: start_date,
          	finish_date: null,
          	note: "",
          	hours: null,
          	minutes: null,
          	status: 5,
          	edited: true
          }
        });

		calendar.render();
		calendar.gotoDate(start_date);
	}

	setEventStartAtDate(start_date);
}

function createFinishEvent(finish_date) {

	if (typeof finish_date == "string") {
		finish_date = new Date(finish_date);
	}

	const event_id = -(calendar.getEvents().length + 1);
	let existing_event = getEventAtDate(finish_date);

	if (existing_event == null) {
		existing_event = calendar.addEvent({
    	  id: event_id,
        title: "Played",
        start: finish_date,
        end: addDays(finish_date, 1),
        backgroundColor: '#ea377a',
        borderColor: '#ea377a',
        allDay: true,
        extendedProps: {
        	start_date: null,
        	finish_date: finish_date,
        	note: "",
        	hours: null,
        	minutes: null,
        	status: 5,
        	edited: true
        }
      });

		calendar.render();
		calendar.gotoDate(finish_date);
	}

	setEventFinishAtDate(finish_date);
}

function setEventStartAtDate(str_date) {
	let date = str_date;

	if(typeof str_date == "string") {
		date = new Date(str_date);
	}

	$.each(calendar.getEvents(), function(index, event) {
		if (event.extendedProps.start_date) {
			event.setExtendedProp('start_date', null);
			event.setExtendedProp('edited', true);
		}
		
		if (date < event.end && date >= event.start) {
			event.setExtendedProp('start_date', date);
			event.setExtendedProp('edited', true);

      $('[data-date]').removeClass('start-tag');

			const date_sel = formatFullDate(date, true);
			$('[data-date=' + date_sel + ']').addClass('start-tag').addClass('position-relative');
			setLogDateField('#started-on-fillin', date);
		}
	});
}

function setEventFinishAtDate(str_date) {
	let date = str_date;

	if(typeof str_date == "string") {
		date = new Date(str_date);
	}

	$.each(calendar.getEvents(), function(index, event) {
		if (event.extendedProps.finish_date) {
			event.setExtendedProp('finish_date', null);
			event.setExtendedProp('edited', true);
		}
		
		if (date < event.end && date >= event.start) {
			event.setExtendedProp('finish_date', date);
			event.setExtendedProp('edited', true);

      $('[data-date]').removeClass('finish-tag');

			const date_sel = formatFullDate(date, true);
			$('[data-date=' + date_sel + ']').addClass('finish-tag').addClass('position-relative');
			setLogDateField('#finished-on-fillin', date);
		}
	});
}

function setLogDateField(selector, date) {
	$('#log-dates-section-control').prop('checked', true);
	$('#log-dates-section').removeClass('d-none');

	$(selector).addClass('filled').attr('date', date);
	$(selector + " .date-text").html(formatFullDate(date));
}

function unsetLogDateField(selector) {
	$(selector).removeClass('filled').attr('date', null);
	$(selector + " .date-text").html('');

	if (!$('#log-dates-section .fill-in').hasClass('filled')) {
		$('#log-dates-section-control').prop('checked', false);
		$('#log-dates-section').addClass('d-none');
	}
}

function getEventAtDate(date) {

	let date_event = null;

	$.each(calendar.getEvents(), function(index, event) {
		if (new Date(date) < new Date(event.end) && new Date(date) >= new Date(event.start)) {
			date_event = event;
			return;
		}
	});
	return date_event;
}

// Helper function that returns just the date from an JS Date obj in ISO format
function getDateFromISO(date) {
	const year = date.getFullYear();
	let month = date.getMonth()+1;
	let dt = date.getDate();

	if (dt < 10) {
	  dt = '0' + dt;
	}
	if (month < 10) {
	  month = '0' + month;
	}

	return year + '-' + month + '-' + dt;
}

// Given calendar events, will turn into an array of PlayDates
function eventsToPlayDates(events) {
	let play_dates = [];

	$.each(events, function(index, event){
		// Converting to a format with just the date to avoid timezone issues on server-side
		const event_start = getDateFromISO(event.start);
		const event_end = getDateFromISO(event.end);

		play_dates.push({
			id: event.id,
			range_start_date: event_start,
			range_end_date: event_end,
			edited: event.extendedProps.edited,
			status: event.extendedProps.status,
			note: event.extendedProps.note,
			hours: event.extendedProps.hours,
			minutes: event.extendedProps.minutes,
			start_date: event.extendedProps.start_date,
			finish_date: event.extendedProps.finish_date,
			privacy: event.extendedProps.privacy,
			tags: event.extendedProps.tags
		})
	});

	return play_dates;
}

// Given PlayDates, will turn into an array of Calendar events
function playDatesToEvents(play_dates) {
	let calendar_events = [];

	$.each(play_dates, function(index, play_date){
		calendar_events.push({
			id: play_date.id,
      title: 'Played',
      start: play_date.range_start_date,
      end: play_date.range_end_date,
      allDay: true,
      backgroundColor: '#ea377a',
      borderColor: '#ea377a',
      extendedProps: {
        edited: play_date.edited,
        status: play_date.status,
        note: play_date.note,
        hours: play_date.hours,
        minutes: play_date.minutes,
        start_date: play_date.start_date,
        finish_date: play_date.finish_date,
        privacy: play_date.privacy,
        tags: play_date.tags
      }
		});
	});

	return calendar_events;
}

function buildCalendar(curr_dates) {
	if (calendar != null) {
		calendar.destroy();
		calendar = null;
	}

	const calendarEl = $('#playthrough-calendar');
	if (calendarEl[0] == null) {
		return;
	}

	calendar = new Calendar(calendarEl[0], {
		timeZone: 'local',
		locales: allLocales,
		locale: navigator.language,
    plugins: [dayGridPlugin, interactionPlugin],
    defaultView: 'dayGridMonth',
    defaultDate: getLatestDate(curr_dates),
    forceEventDuration: true, // Fixes any broken range_end_date that match the range_start_date
    longPressDelay: 10,
    selectable: true,
    selectOverlap: false,
    contentHeight: 'auto',
    events: curr_dates,
    header: false,
    editable: true,
    eventOverlap: false,
    select: function(info) {
    	calendar.addEvent({
    	  id: -(calendar.getEvents().length + 1),
        title: 'Played',
        start: info.start,
        end: info.end,
        backgroundColor: '#ea377a',
        borderColor: '#ea377a',
        allDay: true,
        extendedProps: {
        	note: "",
        	hours: null,
        	minutes: null,
        	status: 5,
        	edited: true,
        	finish_date: null,
        	start_date: null,
        	privacy: null
        }
      });

    	// if(playthroughs[$('#playthrough-selector').val()].start_date_id != null && info.start < calendar.getEventById(playthroughs[$('#playthrough-selector').val()].start_date_id).start) {
    	// 	setEventStart();
    	// }
    },
    eventClick: function(info) {
    	openPlayDate(info.event);
    },
    eventResize: function(info) {
    	info.event.setExtendedProp('edited', true);

    	if (info.event.extendedProps.finish_date) {
    		let finish_date = new Date($('#finished-on-fillin').attr('date'));
    		let new_end_date = addDays(info.event.end, -1);

    		if (new_end_date < finish_date) {
    			setEventFinishAtDate(new_end_date);
    		}
    	}

    	if (info.event.extendedProps.start_date) {
    		let start_date = new Date($('#started-on-fillin').attr('date'));
    		let new_end_date = addDays(info.event.end, -1);

    		if (new_end_date < start_date) {
    			setEventStartAtDate(new_end_date);
    		}
    	}
    },
    eventDrop: function(info) {
    	info.event.setExtendedProp('edited', true);
    	const days_moved = info.delta.days
    	const start_date = new Date($('#started-on-fillin').attr('date'));
    	const finish_date = new Date($('#finished-on-fillin').attr('date'));

    	if (info.event.extendedProps.start_date) {
    		if (start_date && finish_date && info.event.start > finish_date && start_date.getTime() !== finish_date.getTime()) {
    			setEventStartAtDate(finish_date);
    		} else {
    			setEventStartAtDate(addDays(start_date, days_moved));
    		}
    	}

    	if (info.event.extendedProps.finish_date) {
    		const new_end_date = addDays(info.event.end, -1);

    		if (start_date && finish_date && new_end_date < start_date && start_date.getTime() !== finish_date.getTime()) {
	    		setEventFinishAtDate(start_date);
	    	} else {
	    		setEventFinishAtDate(addDays(finish_date, days_moved));
	    	}
    	}
    },
    datesRender: function(info) {
    	if($('#finished-on-fillin').attr('date') != undefined) {
      	setEventFinishAtDate($('#finished-on-fillin').attr('date'));
    	}

    	if($('#started-on-fillin').attr('date') != undefined) {
      	setEventStartAtDate($('#started-on-fillin').attr('date'));
    	}
    }
	});

	saveCurrentPlaythroughData();

	if (curr_dates.length == 1) {
		$('#playthrough-date-range').html(curr_dates.length + " Session Logged");
	} else if (curr_dates.length > 1) {
		$('#playthrough-date-range').html(curr_dates.length + " Sessions Logged");
	}

  calendar.render();
  calendar.updateSize();

  update_all_changes();
  
	const date = calendar.getDate();
	$('#month-selector').selectpicker('val', date.getMonth());
	$('#year-selector').selectpicker('val', date.getFullYear());
}

var setupCalendar = function() {
	const playthrough_id = $('#current_playthrough').val();
	const game_id = $('.game-id-container').attr('game_id');

	const adv_opened = Cookies.get('advanced_open');
	if (adv_opened === 'true') {
		$('#log-advanced-section-control').addClass('fa-rotate-180');
		$('#log-advanced-section').removeClass('d-none');
		if ($('#btn-destroy-all-logs-v2').attr('log_id') && $('#btn-destroy-all-logs-v2').attr('log_id') !== "") {
			$('#btn-destroy-all-logs-v2').removeClass('d-none');
		} else {
			$('#btn-destroy-all-logs-v2').addClass('d-none');
		}
	}

	if (playthroughs[playthrough_id] && playthroughs[playthrough_id].play_dates && playthroughs[playthrough_id].play_dates.length > 0) {
		console.log("Build existing calendar...")
		buildCalendar(playDatesToEvents(playthroughs[playthrough_id].play_dates));
	} else {
		console.log("Build empty calendar...")
		buildCalendar([]);
	}
}

function mobileNavigationSelect(nav_type) {
	if (nav_type == "library") {
		$("#mobile-library-panel").addClass('current');
	} else {
		$('.mobile-panel .mobile-sub-panel').removeClass('current');
		$('.mobile-panel .mobile-sub-panel#mobile-' + nav_type + '-panel').addClass('current');
		$("#mobile-library-panel").removeClass('current');
	}

	if (nav_type == "journal" && $(window).width() < 950 && $(window).height() < 745) {
		$('#mobile-library-panel').addClass('hide-panel');
	} else {
		$('#mobile-library-panel').removeClass('hide-panel');
	}

	$('#journal-bottom-navigation .nav-button').removeClass('current');
	$('#journal-bottom-navigation .nav-button i').removeClass('fas').addClass('fal');
	$('#journal-bottom-navigation .nav-button[type="' + nav_type + '"]').addClass('current');
	$('#journal-bottom-navigation .nav-button[type="' + nav_type + '"] i').addClass('fas').removeClass('fal')
}

function toggleLogView() {
	const modalType = getModalType();

	if (modalType == "hybrid") {
		$('#game-log').toggleClass('d-none');
		$('#game-journal').toggleClass('d-none');
		$('#daily-journal-view-btn .col').toggleClass('current');
	} else if (modalType == "quick") {
		$('#game-log').toggleClass('d-none');
		$('#game-journal').toggleClass('d-none');
		$('.journal-section-nav').removeClass('current');
		$('.journal-section-nav#journal-nav').addClass('current');
	} else if (modalType == "mobile") {
		mobileNavigationSelect("journal");
	}
}

// Load library entries data into the UI
function fillLibraryEntries(library_entries) {
	if (!lodash_isEmpty(library_entries)) {
		const library_entries_num = lodash_size(library_entries);

		$.each(library_entries, function(id, entry){
			$('#journal-log-library-entries-modal input[platform_id="' + entry.platform_id + '"][ownership_id="' + entry.ownership_id + '"]').attr('entry_id', id).prop('checked', true);
		});

		library_entry_promise = new Promise((resolve, reject) => {
			$.ajax({
				type: "GET",
				url: "/collections/render/",
				data: { entries: library_entries, modal_type: getModalType() },
				success(res){
					$('#library-entries-empty').addClass('d-none');
					$('#library-entries-headers').removeClass('d-none');
					$('#journal-game-modal .collection-section').html(res.partials);
					resolve();
					if (getModalType() == "full") {
						$('#library-entries-full .collection-section-full').html(res.full_partials);

						if (library_entries_num < 4) {
							$('#library-entries-more').addClass('d-none');
						} else {
							const library_num_diff = (library_entries_num - 3);
							$('#library-entries-more').removeClass('d-none');
							$('#library-entries-more p').text('+ ' + library_num_diff + ' other' + (library_num_diff > 1 ? 's' : ''))
						}

						$('.library-entry-display .platform-entry-full').each(function(index){
							const plat_id = $(this).attr('platform_id');
							$(this).html($('#game-platform-options option[value="' + plat_id + '"]').html());
						});
					}
					onmount();
					new_library_entry_id -= library_entries_num;
				},
				error(err) {
					resolve();
				}
			})
		});
	} else {
		$('#library-entries-more').addClass('d-none');
	}
}

var fillLogFields = function() {
	if (lodash_isEmpty(log_data)) {
		return;
	}

	$('#journal-game-modal #log_id').val(log_data.id);
	$('#btn-destroy-all-logs button, #btn-destroy-all-logs-v2').attr('log_id', log_data.id);
	
	if (log_data.id !== "" && (getModalType() === "full" || getModalType() === "mobile")) {
		$('#btn-destroy-all-logs-v2').removeClass('d-none');
	}

	$('#journal-game-modal #play-label-title').html(capitalizeFirstLetter(log_data.status));
	$('#journal-game-modal #status').val(log_data.status);
	$('#journal-game-modal #game-status-selector').removeClass('completed mastered abandoned shelved retired played').addClass(log_data.status);
	$('#journal-game-modal #play-toggle-label').removeClass('completed mastered abandoned shelved retired played').addClass(log_data.status);
	$('#journal-game-modal #log_liked_checkbox').prop('checked', log_data.game_liked);

	$('#journal-game-modal #play_toggle_checkbox').prop('checked', log_data.is_play);
	$('#journal-game-modal #playing_toggle_checkbox').prop('checked', log_data.is_playing);
	$('#journal-game-modal #backlog_toggle_checkbox').prop('checked', log_data.is_backlog);
	$('#journal-game-modal #wishlist_toggle_checkbox').prop('checked', log_data.is_wishlist);

	const hours = log_data.total_hours
	const mins = log_data.total_minutes;

	const show_placeholder = log_data.time_source == 0 || (hours == undefined && mins == undefined) || (hours == 0 && mins == 0)

	if (log_data.time_source == 0) {
		$('#journal-game-modal #log-time-played-hours').val(log_data.total_hours);
		$('#journal-game-modal #log-time-played-minutes').val(log_data.total_minutes);
	}

	if (show_placeholder) {
		$('#journal-game-modal #log-time-played-hours').prop('placeholder', 'HH');
		$('#journal-game-modal #log-time-played-minutes').prop('placeholder', 'MM');
	} else {
		$('#journal-game-modal #log-time-played-hours').prop('placeholder', log_data.total_hours_formatted);
		$('#journal-game-modal #log-time-played-minutes').prop('placeholder', log_data.total_minutes_formatted);
	}

	$('#journal-game-modal #log-time-played-hours').prop('disabled', log_data.time_source !== 0);
	$('#journal-game-modal #log-time-played-minutes').prop('disabled', log_data.time_source !== 0);
	$('#journal-game-modal #use-logs-time').prop('checked', log_data.time_source === 1);
	$('#journal-game-modal #use-sessions-time').prop('checked', log_data.time_source === 2);

	fillLibraryEntries(log_data.library_entries);
}

window.fillPlaythroughFields = function(playthrough_id) {
	const data = playthroughs[playthrough_id];

	if (playthrough_id >= 0) {
		$('.save-log').html('Save Changes');
	} else {
		$('.save-log').html('Create Log');
	}

	$('#playthrough_platform').html($('#game-platform-options').html());

	if ($('#playthrough_platform option[value="' + data.platform + '"]').length == 0) {
		$('#playthrough_platform').append($('#playthrough-platform-emulated option[value="' + data.platform + '"]').clone());
		$('#playthrough_platform option[value="' + data.platform + '"]').attr('data-icon', 'fas fa-exclamation-triangle').attr('data-subtext', "removed");
	}

	$('#playthrough_platform').selectpicker('refresh');


	const not_first_playthrough = playthrough_id < -1;
	const show_time_values = data.minutes > 0 || data.hours > 0

	$('#current_playthrough').attr('created_at', data.created_at ? data.created_at : null);
	$('#modal-rating input[name="rating_modal"]').prop('checked', false);
	$('#modal-rating #rating_modal_' + data.rating).prop('checked', true);
	if (data.rating > 0) {
		$('#modal-rating .cancel-rating').removeClass('d-none');
	} else {
		$('#modal-rating .cancel-rating').addClass('d-none');
	}
	$('#review').val(data.review);
	$('#log_spoilers_checkbox').prop('checked', data.review_spoilers ? data.review_spoilers : false);
	$('#playthrough_platform').selectpicker('val', data.platform ? data.platform : null);
	$('#playthrough-platform-emulated').selectpicker('val', data.played_platform ? data.played_platform : data.platform);
	$('#playthrough-platform-emulated').attr('user-edited', data.played_platform !== null);
	$('#playthrough-medium').selectpicker('val', data.medium_id);
	$('#playthrough-time-played-hours').val(show_time_values ? data.hours : null);
	$('#playthrough-time-played-minutes').val(show_time_values ? data.minutes : null);
	$('#playthrough-mastered').prop('checked', data.is_master ? data.is_master : false);
	$('#playthrough-replay').prop('checked', data.is_replay ? data.is_replay : not_first_playthrough);
	$('#log-title-field').val(data.title);
	$('.journal-section-nav#log-nav').html(data.title);

	// If the playthrough is the first and new, don't show log selector
	if (playthrough_id === -1) {
		$('#log-selector-btn-container').addClass('d-none');
	} else {
		$('#log-selector-btn-container').removeClass('d-none');
		$('#log-selector-btn-text').html(data.title);
	}

	if (data.start_date != undefined || data.finish_date != undefined) {
		$('#log-dates-section-control').prop('checked', true);
		$('#log-dates-section').removeClass('d-none');
	}

	if (data.start_date != undefined) {
		setLogDateField('#started-on-fillin', new Date(data.start_date + "T00:00"));
	} else {
		unsetLogDateField('#started-on-fillin');
	}

	if (data.finish_date != undefined) {
		setLogDateField('#finished-on-fillin', new Date(data.finish_date + "T00:00"));
	} else {
		unsetLogDateField('#finished-on-fillin');	
	}
}

function saveCurrentLogData(type) {
	const library_entries = getCurrentLibraryEntries();
	let time_source = 0;

	if ($(".time-checkbox-option#use-logs-time").is(":checked")) {
		time_source = 1;
	} else if ($(".time-checkbox-option#use-sessions-time").is(":checked")) {
		time_source = 2;
	}

	if (lodash_isEmpty(log_data)) {
		log_data = {}
	}

	log_data.game_liked = $('#log_liked_checkbox').is(':checked');
	log_data.is_play = $('#play_toggle_checkbox').is(':checked');
	log_data.is_playing = $('#playing_toggle_checkbox').is(':checked');
	log_data.is_backlog = $('#backlog_toggle_checkbox').is(':checked');
	log_data.is_wishlist = $('#wishlist_toggle_checkbox').is(':checked');
	log_data.status = $('#status').val();
	log_data.id = $('#log_id').val();

	// Only overwrite data where fields are present or data will get wiped by reading undefined fields
	if (type === "full" || type === "hybrid" || type === "mobile") {
		log_data.library_entries = library_entries;
		log_data.total_hours = $('#log-time-played-hours').val();
		log_data.total_minutes = $('#log-time-played-minutes').val();
		log_data.time_source = time_source;
	}
}

function saveCurrentPlaythroughData() {
	
	const playthrough_id = $('#current_playthrough').val();


	if (!(playthrough_id in playthroughs)) {
		playthroughs[playthrough_id] = {id: playthrough_id};
	}

	const mediums = $('#playthrough-medium').val();
	let medium_id = null;

	if (mediums != null) {
		medium_id = mediums[0];
	}

	const platforms = $('#playthrough_platform').val();
	let platform_id = null;

	if (platforms != null) {
		platform_id = platforms[0];
	}

	const played_platforms = $('#playthrough-platform-emulated').val();
	let played_platform_id = null;

	if (played_platforms != null && $('#playthrough-platform-emulated').attr('user-edited') === "true") {
		played_platform_id = played_platforms[0];
	}

	const start_date_raw = $('#started-on-fillin').attr('date');
	const finish_date_raw = $('#finished-on-fillin').attr('date');

	playthroughs[playthrough_id].created_at				= $('#current_playthrough').attr('created_at');
	playthroughs[playthrough_id].rating 					= $('#modal-rating input[name="rating_modal"]:checked').val();
	playthroughs[playthrough_id].review 					= $('#review').val()
	playthroughs[playthrough_id].review_spoilers	= $('#log_spoilers_checkbox').is(':checked')
	playthroughs[playthrough_id].platform 				= platform_id;
	playthroughs[playthrough_id].hours 						= $('#playthrough-time-played-hours').val()
	playthroughs[playthrough_id].minutes 					= $('#playthrough-time-played-minutes').val()
	playthroughs[playthrough_id].is_master 				= $('#playthrough-mastered').is(':checked')
	playthroughs[playthrough_id].is_replay 				= $('#playthrough-replay').is(':checked')
	playthroughs[playthrough_id].title 						= $('#log-title-field').val();
	playthroughs[playthrough_id].play_dates 			= eventsToPlayDates(calendar.getEvents());
	playthroughs[playthrough_id].start_date 			= start_date_raw ? formatFullDate(new Date(start_date_raw), true) : null;
	playthroughs[playthrough_id].finish_date 			= finish_date_raw ? formatFullDate(new Date(finish_date_raw), true) : null;

	// Only overwrite data where fields are present or data will get wiped by reading undefined fields
	const modal_type = getModalType();
	if (modal_type === "full" || modal_type === "hybrid" || modal_type === "mobile") {
			playthroughs[playthrough_id].medium_id = medium_id;
			playthroughs[playthrough_id].played_platform 	= played_platform_id;
	}
}

function loadAllPlaythroughs(callback) {
	const game_id = $('.game-id-container').attr('game_id');

	$.ajax({
		url: "/playthroughs/game/" + game_id,
		method: "GET",
		success(res) {
			$.each(res, function(id, playthrough){
				if (!(id in playthroughs)) {
					playthroughs[id] = playthrough;
					console.log("Added " + id + " to playthroughs");
				}
			});
			callback();
		}
	})
}

function loadPlaythrough(playthrough_id, callback) {
	let playthrough_data = {}

	if (playthrough_id == null) {
		callback(playthrough_data);
	} else if (playthrough_id in playthroughs) {
		playthrough_data = playthroughs[playthrough_id]
		callback(playthrough_data);
	} else if (playthrough_id > 0) {
		$.ajax({
			url: "/playthrough/" + playthrough_id,
			method: "GET",
			success(res) {
				playthrough_data = res;
				playthroughs[res.id] = playthrough_data;
				callback(playthrough_data);
			}
		})
	} else {
		console.log("WARNING: Could not load playthrough with id " + playthrough_id)
		callback(null);
	}
}

function getAllData() {
	const game_id = $('.game-id-container').attr('game_id');
	saveCurrentLogData(getModalType());

	let all_log_data = { log: $.extend(true, {}, log_data), game_id: game_id };

	if (calendar == null || playthroughs.length == 0) {
		return all_log_data;
	}

	saveCurrentPlaythroughData();

	all_log_data['playthroughs'] = $.extend(true, {}, playthroughs);

	return all_log_data;
}

window.openLogModal = function(game_id, when_complete=function(){}, playthrough_id=null, replay_playthrough_id=null){
	check_for_unsaved_changes = false;
	all_changes_needs_update = true;

	$.ajax({
		type: 'GET',
		url: "/tags/recent",
		success(res) {
			most_recent_tags = res.tags;
		}
	});

	$.ajax({
		type: 'GET',
		url: playthrough_id == null ? '/playthrough/game/' + game_id : '/playthrough/' + playthrough_id,
		success(res) {
			const playthrough_data = res["playthrough"];
			log_data = res["game_log"];
			let curr_playthrough_id = undefined;

			if (replay_playthrough_id) {
				curr_playthrough_id = replay_playthrough_id;
				playthroughs[curr_playthrough_id] = createPlaythrough(curr_playthrough_id);
				console.log("New replay, opening modal...");
			} else if (!$.isEmptyObject(playthrough_data)) {
				curr_playthrough_id = playthrough_data["id"];
				playthroughs[playthrough_data["id"]] = playthrough_data;
				console.log("Playthrough found, opening modal...")
			} else {
				curr_playthrough_id = -1;
				playthroughs[curr_playthrough_id] = createPlaythrough(curr_playthrough_id);
				console.log("Playthrough not found, opening modal...");
			}

			$.ajax({
				type: 'POST',
				url: '/autocomplete-modal/' + game_id,
				data: { modal_type: $(window).width() < 950 ? 'mobile' : Cookies.get('game-log-editor-mode') },
				success(res_modal) {
					$('div#journal-game-modal .modal-content').html(res_modal);
					$('#journal-game-modal').modal({
						focus: false // https://github.com/rjewell808/backloggd/issues/1139 - Fixes bug in Firefox where a nested micro-modal couldn't focus on text-fields 
					});
					$('#current_playthrough').val(curr_playthrough_id);
					fillLogFields();
					fillPlaythroughFields(curr_playthrough_id);

					if (getModalType() == "full") {
						$('#journal-game-modal .modal-content .modal-header').addClass('d-none');
						$('#journal-game-modal').addClass('extended-modal');
					} else if (getModalType() == "mobile") {
						$('#journal-game-modal').addClass('modal-fullscreen');
					}
					setupCalendar();
					when_complete();
				}
			});
		}
	});
}

function saveLog(user_id, button) {
	const journal_data = getAllData();

	let playthroughs_to_update = [];
	let play_dates_to_update = {};

	$.each(journal_data.playthroughs, function(index, playthrough){
		console.log(index + " edited: " + !lodash_isEqual(playthrough, all_changes.playthroughs[index]));
		//const difference = Object.keys(playthrough).filter(k => playthrough[k] !== all_changes.playthroughs[index][k]);

		$.each(playthrough.play_dates, function(index, play_date){
			if (play_date.edited) {
				if (play_dates_to_update[playthrough.id] == undefined) {
					play_dates_to_update[playthrough.id] = [play_date];
				} else {
					play_dates_to_update[playthrough.id].push(play_date);
				}
			}
		});

		if (!lodash_isEqual(playthrough, all_changes.playthroughs[index])) {
			// Remove here since we already checked for differences above to prevent sending the data twice to the server
			delete playthrough.play_dates;

			playthroughs_to_update.push(playthrough);
		}

		//console.log(difference);
		console.log(playthrough);
		console.log(all_changes.playthroughs[index]);
	});

	if (button !== undefined) {
		button.prop("disabled", true);
	}

	$.ajax({
		type: "POST",
		url: '/api/user/' + user_id + '/log/' + journal_data.game_id,
		data: {
			game_id: journal_data.game_id, 
			playthroughs: playthroughs_to_update, 
			dates: play_dates_to_update,
			log: journal_data.log,
			deleted_dates: deleted_dates,
			modal_type: getModalType()
		},
		success: function(res) {
			check_for_unsaved_changes = false;
			const modal_type = getModalType();
			if (modal_type != "mobile") {
				Cookies.set("game-log-editor-mode", modal_type, { sameSite: 'strict', expires: 365 });
			}
			$('#journal-game-modal').modal('hide'); // Has to be last since hiding the modal wipes temp data
		}
	});	
}

// Fetch library entries data from UI
function getCurrentLibraryEntries() {
	let library_entries = {}

	if (getModalType() !== "full") {
		$('.library-entry').each(function(index){
			const entry_id = $(this).attr('entry_id');
			library_entries[entry_id] = {
				platform_id: $('.library-plat-selectpicker[entry_id="' + entry_id + '"]').val(),
				ownership_id: $('.library-entry-ownership[entry_id="' + entry_id + '"]').val()[0]
			}
		});
	} else {
		$('#journal-log-library-entries-modal input:checked').each(function(index){
			const platform_id = $(this).attr('platform_id');
			const ownership_id = $(this).attr('ownership_id');
			const entry_id = $(this).attr('entry_id');

			library_entries[entry_id] = {
				platform_id: platform_id,
				ownership_id: ownership_id
			}
		});
	}

	return library_entries;
}

onmount('#library-entries-full', function(){
	$(this).on('click', function(){
		micro_modal.show('journal-log-library-entries-modal', {
			awaitOpenAnimation: true,
			awaitCloseAnimation: true,
			onClose: function(){
				fillLibraryEntries(getCurrentLibraryEntries());
			}
		});
	});
});

// When creating a new log while one already exists
onmount('#open-new-game-log-modal-btn', function(){
	$(this).on('click', function(){
		const game_id = $(this).attr('game_id');
		const new_id = -(Math.abs($(this).attr('num_playthroughs')) + 1);

		openLogModal(game_id, function(){}, null, new_id);
	});
});

onmount('#switch-editor-to-full', function(){
	$(this).on('click', function(){
		const game_id = $('.game-id-container').attr('game_id');
		const curr_playthrough_id = $('#current_playthrough').val();

		saveCurrentLogData(getModalType());
		saveCurrentPlaythroughData();

		$('.save-log').prop('disabled', true);

		$.ajax({
				type: 'POST',
				url: '/autocomplete-modal/' + game_id,
				data: { modal_type: "full" },
				success(res_modal) {
					$('div#journal-game-modal .modal-content').html(res_modal);
					$('#current_playthrough').val(curr_playthrough_id);
					fillLogFields();
					fillPlaythroughFields(curr_playthrough_id);
					setupCalendar();
					onmount();
				}
			});
			setAsLoading($('div#journal-game-modal .modal-content .modal-body'));
			$('#journal-game-modal .modal-content .modal-header').addClass('d-none');
			$('#journal-game-modal').addClass('extended-modal');
	});
});

onmount('#switch-editor-to-quick', function(){
	$(this).on('click', function(){
		const game_id = $('.game-id-container').attr('game_id');
		const curr_playthrough_id = $('#current_playthrough').val();

		saveCurrentLogData(getModalType());
		saveCurrentPlaythroughData();

		$('.save-log').prop('disabled', true);
		
		$.ajax({
				type: 'POST',
				url: '/autocomplete-modal/' + game_id,
				data: { modal_type: "quick" },
				success(res_modal) {
					$('div#journal-game-modal .modal-content').html(res_modal);
					$('#current_playthrough').val(curr_playthrough_id);
					fillLogFields();
					fillPlaythroughFields(curr_playthrough_id);
					setupCalendar();
					onmount();
				}
			});

			setAsLoading($('div#journal-game-modal .modal-content .modal-body'));
			$('#journal-game-modal').removeClass('extended-modal');
	});
});

// onmount('#log-editor-full', function(){
// 	$('#add-a-game').prop('disabled', true);
// 	const game_id = $(this).attr('game_id');
// 	openLogModal(game_id, function(){}, null, "full");
// });

onmount('.open-log', function(){
	$(this).on('click', function(){
		const game_id = $(this).attr('game_id');
		const playthrough_id = $(this).attr('playthrough_id');
		openLogModal(game_id, function(){}, playthrough_id);
	});
});

onmount('.quick-access-cover', function(){
	$(this).on('click', function(){
		const game_id = $(this).attr('game_id');
		openLogModal(game_id, function(){
			toggleLogView();
		});
	});
});

onmount('#open-game-log-modal-btn, .quick-journal', function(){
	$(this).on('click', function(){
		const game_id = $(this).attr('game_id');
		openLogModal(game_id);
	})
});

onmount('#journal-bottom-navigation .nav-button', function(){
	$(this).on('click', function(){
		const type = $(this).attr("type");
		mobileNavigationSelect(type);
	});
});

// PlayDate Modal elements

onmount('#play-date-update', function(){
	$(this).on('click', function(){
		micro_modal.close('playthrough-modal');
		
		let tags_parsed = []

		$.each(tagify.value, function(index, tag_data){
			tags_parsed.push(tag_data.value);
		})

		let event = calendar.getEventById($('#play-date-id').attr('date_id'));
		const update_is_finish = $('#is_finish_session').is(":checked");
		let curr_finish_date = event.extendedProps.finish_date;

		const update_is_start = $('#is_start_session').is(":checked");
		let curr_start_date = event.extendedProps.start_date;

		if (curr_finish_date && !update_is_finish) {
			$('[data-date]').removeClass('finish-tag');
			unsetLogDateField('#finished-on-fillin');
			curr_finish_date = null
		} else if (curr_finish_date === null && update_is_finish) {
			curr_finish_date = addDays(event.end, -1);
			setEventFinishAtDate(curr_finish_date);
			$('#play_toggle_checkbox').prop("checked", true);
		}

		if (curr_start_date && !update_is_start) {
			$('[data-date]').removeClass('start-tag');
			unsetLogDateField('#started-on-fillin');
			curr_start_date = null;
		} else if (curr_start_date === null && update_is_start) {
			curr_start_date = event.start;
			setEventStartAtDate(curr_start_date);
		}

		event.setExtendedProp('note', $('#playthrough-modal #note').val());
		// Setting default to 5 (played status) as it'll be deprecated soon to only be played
		event.setExtendedProp('status', 5);
		event.setExtendedProp('hours', $('#playthrough-modal #play_date_hours').val());
		event.setExtendedProp('minutes', $('#playthrough-modal #play_date_minutes').val());
		event.setExtendedProp('privacy', $('#playthrough-modal #note-privacy-dropdown .nav-link.current-sort').attr('privacy'));
		event.setExtendedProp('finish_date', curr_finish_date);
		event.setExtendedProp('start_date', curr_start_date);
		event.setExtendedProp('tags', tags_parsed);
		event.setExtendedProp('edited', true);
	});
});

onmount('#play-date-close', function(){
	$(this).on('click', function(){
		micro_modal.close('playthrough-modal');
	});
});

onmount('#play-date-delete', function(){
	$(this).on('click', function(){
		blConfirmation("Are you sure you want to delete this play session?",
			function(){},
			function(){
				micro_modal.close('playthrough-modal');
				let event = calendar.getEventById($('#play-date-id').attr('date_id'));
				let curr_playthrough_id = $('#current_playthrough').val();
				if (event.id >= 0) {
					if (deleted_dates[curr_playthrough_id]) {
						deleted_dates[curr_playthrough_id].push(event.id);
					} else {
						deleted_dates[curr_playthrough_id] = [event.id];
					}
				}

				if(event.extendedProps.finish_date) {
					$('[data-date]').removeClass('finish-tag');
					$('#finished-on-fillin').removeClass('filled');
					$('#finished-on-fillin').attr('date', null);
		      $('#finished-on-fillin .date-text').html('');
				}

				event.remove();

				if(event.extendedProps.start_date) {
					$('[data-date]').removeClass('start-tag');
					$('#started-on-fillin').removeClass('filled');
					$('#started-on-fillin').attr('date', null);
		      $('#started-on-fillin .date-text').html('');
				}
			}, "Cancel", "Delete");
	});
});

// Journal Log elements

onmount('.save-log', function(){
	$(this).on('click', function(){
		saveLog($(this).attr('user_id'), $(this));
	});
});

onmount('#new-collection-entry-btn', function(){
	$(this).on('click', function(){
		$.ajax({
			type: "GET",
			url: "/collections/new/render/" + new_library_entry_id,
			success(res){
				$('#library-entries-empty').addClass('d-none');
				$('#library-entries-headers').removeClass('d-none');
				$('.collection-section').append(res);
				onmount();
				new_library_entry_id -= 1;
			}
		})
	});
});

onmount('.remove-library-entry', function(){
	$(this).on('click', function(){
		const entry_id = $(this).attr('entry_id');
		$('.library-entry[entry_id="' + entry_id + '"]').remove();

		if ($('.collection-section').children().length < 1){
			$('#library-entries-empty').removeClass('d-none');
			$('#library-entries-headers').addClass('d-none');
		}
	});
});

onmount('#daily-journal-view-btn', function(){
	$(this).on('click', function(){
		toggleLogView();
	});
});

onmount('.journal-section-nav', function(){
	$(this).on('click', function(){
		if($(this).hasClass('current')) {
			return
		}

		$('.journal-section-nav').removeClass('current');
		$(this).addClass('current');

		let beta_nav = false;

		if ($(this).hasClass('beta-nav')) {
			beta_nav = true;
		}

		if($('.journal-section-nav#log-nav').hasClass('current')) {
			$('#btn-more-log-options, #log-view-btns, #daily-journal-view-btn').removeClass('d-none');

			$('#game-collection, #log-details-view-btn').addClass('d-none');

			if ($('#btn-destroy-all-logs button').attr('log_id') && $('#btn-destroy-all-logs button').attr('log_id') !== "") {
				$('#btn-destroy-all-logs').addClass('d-none');
			}

			if (beta_nav) {
				$('#game-log').removeClass('d-none');
				$('#game-journal').addClass('d-none');
			} else {
				$('#log-toggle-buttons').addClass('d-none');

				if($('#log-view-toggle-log-btn').hasClass('current')) {
					$('#game-log').removeClass('d-none');
				} else {
					$('#game-journal').removeClass('d-none');
				}
			}

			// $('#journal-game-modal').removeClass('extended-modal');
		} else if($('.journal-section-nav#collection-nav').hasClass('current')) {
			$('#game-log, #log-view-btns, #game-journal').addClass('d-none');
			$('#game-collection, #log-toggle-buttons').removeClass('d-none');
			if ($('#btn-destroy-all-logs button').attr('log_id') && $('#btn-destroy-all-logs button').attr('log_id') !== "") {
				$('#btn-destroy-all-logs').removeClass('d-none');
			}
			// $('#journal-game-modal').addClass('extended-modal');
		} else if($('.journal-section-nav#journal-nav').hasClass('current')) {
			$('#game-log').addClass('d-none');
			$('#game-journal').removeClass('d-none');
		}
	})
});

onmount('#month-prev', function(){
	$(this).on('click', function(){
		calendar.prev();
		var date = calendar.getDate();
		$('#month-selector').selectpicker('val', date.getMonth());
		$('#year-selector').selectpicker('val', date.getFullYear());
	});
});

onmount('#month-next', function(){
	$(this).on('click', function(){
		calendar.next();
		var date = calendar.getDate();
		$('#month-selector').selectpicker('val', date.getMonth());
		$('#year-selector').selectpicker('val', date.getFullYear());
	});
});

onmount('#cancel-journal-rating', function(){
	$(this).on('click', function(){
		$('#modal-rating input').prop("checked", false);
		$('#modal-rating input#rating_modal_0').prop("checked", true);
		$(this).addClass('d-none');
	});
});

onmount('#modal-rating input', function(){
	$(this).on('click', function(){
		$('#cancel-journal-rating').removeClass('d-none');
		// When rating a game, automatically check the played checkbox
		$('#play_toggle_checkbox').prop('checked', true);
	});
});

onmount('#log-selector-btn', function(){
	$(this).on('click', function(){
		const game_id = $('.game-id-container').attr('game_id');

		loadAllPlaythroughs(function(res){			
			let log_render_data = {}

			$.each(playthroughs, function(index, playthrough) {
				log_render_data[playthrough.id] = {
					"title": playthrough.title,
					"created_at": playthrough.created_at,
					"finish_date": playthrough.finish_date
				}
			});

			$.ajax({
				url: "/playthroughs/" + game_id + "/render",
				type: "GET",
				data: { playthroughs: log_render_data },
				contentType: "application/json",
				success: function(res) {
					$('#journal-log-selection-modal #log-container').html(res);
					$('.log-selection[playthrough_id="' + $('#current_playthrough').val() + '"]').addClass('filled');
					onmount();
				}
			});
		});

		$('#journal-log-selection-modal #log-container').html('<div class="spinner"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>')
		micro_modal.show('journal-log-selection-modal', {
			awaitOpenAnimation: true,
			awaitCloseAnimation: true,
		});
	});
});

onmount('.log-selection', function(){
	$(this).on('click', function(){
		if (!$(this).prop('disabled')) {
			const pt_id = $(this).attr('playthrough_id');

			if($('#current_playthrough').val() == pt_id) {
				micro_modal.close('journal-log-selection-modal');
				return;
			}

			$('.log-selection').prop('disabled', true);
			$('.log-selection').removeClass('filled');
			$(this).addClass('filled');
			saveCurrentPlaythroughData();

			$('#current_playthrough').val(pt_id);
			fillPlaythroughFields(pt_id);
			buildCalendar(playDatesToEvents(playthroughs[pt_id].play_dates))
			$('#log-selector-btn-text').html($('#playthrough-' + $(this).attr('playthrough_id') + "-title").html());
			micro_modal.close('journal-log-selection-modal');
		}
	});
});

onmount('#new-log-btn', function(){
	$(this).on('click', function(){
		$(this).prop("disabled", true);
		// Saves current playthrough edits
		saveCurrentPlaythroughData();

		// Generate a new temp id for playthrough
		const new_id = -(Object.keys(playthroughs).length + 1);
		playthroughs[new_id] = createPlaythrough(new_id);

		// Set blank UI fields for new playthrough
		$('#current_playthrough').val(new_id);
		fillPlaythroughFields(new_id);
		buildCalendar([]);

		// Save empty fields to data
		micro_modal.close('journal-log-selection-modal');
		saveCurrentPlaythroughData();
		$(this).prop("disabled", false);
	});
});

$(document).keydown(function (event) {
	if (event.keyCode === 27 && block_esc_key) {
		event.stopImmediatePropagation();
	}
});

onmount('#journal-game-modal', function(){
	console.log("Journal Ready!");
	$(this).on('shown.bs.modal', function(){
		console.log("Start Calendar...");
		block_esc_key = true;
		micro_modal.init({
			awaitOpenAnimation: true,
			awaitCloseAnimation: true,
			debugMode: true
		});
	});

	onmount('#btn-destroy-all-logs button, #btn-destroy-all-logs-v2', function(){
		$(this).on('click', function(){
			const game_id = $('.game-id-container').attr('game_id');
			const log_id = $(this).attr('log_id');
			blConfirmation("Destroy all data about this game? This includes all logs, journal entries, reviews, ratings, library entries, time tracked, etc.. for this game.", function(){}, function(){
				check_for_unsaved_changes = false;
				$('#journal-game-modal').modal('hide');
				
				$.ajax({
					type: "DELETE",
					url: "/unlog/",
					data: { game_id: game_id, log_id: log_id }
				});
			}, "Return", "Destroy")
		});
	});

	$(this).on('hide.bs.modal', function() {
		console.log(check_for_unsaved_changes);

		if (!check_for_unsaved_changes) {
			return true;
		}

		const currData = getAllData();
		// const difference = Object.keys(currData).filter(k => currData[k] !== all_changes[k]);

		if(!lodash_isEqual(currData, all_changes)) {
			blUnsavedWarning("Your logs have unsaved changes.",
				function() {
					check_for_unsaved_changes = false;
					$('#journal-game-modal').modal('hide');
				},
				function() {
					saveLog($('#btn-save-log .save-log').attr('user_id'));
				}
			);
			return false;
		}
	});

	$(this).on('hidden.bs.modal', function(){
		if(calendar != null) {
			calendar.destroy();
			calendar = null
		}

		deleted_dates = {};
		deleted_playthroughs = [];
		playthroughs = {};
		log_data = {};
		check_for_unsaved_changes = true;
		block_esc_key = false;

		$('#journal-game-modal').removeClass('extended-modal').removeClass('modal-fullscreen');
	});
});

onmount('#journal-completion-date-input', function(){
	$(this).on('change', function(){
		$('#journal-completion-date-calendar').datepicker('update', $(this).val());
	});
});

onmount('#jump-to-today', function(){
	$(this).on('click', function(){
		const today_date = new Date();

		$('#year-selector').selectpicker('val', today_date.getFullYear());
		$('#month-selector').selectpicker('val', today_date.getMonth());
		calendar.gotoDate(today_date);
	});
});

onmount('#jump-to-start', function(){
	$(this).on('click', function(){
		const start_date_raw = $('#started-on-fillin').attr('date');

		if (start_date_raw === undefined) {
			return;
		}

		const start_date = new Date(start_date_raw);

		$('#year-selector').selectpicker('val', start_date.getFullYear());
		$('#month-selector').selectpicker('val', start_date.getMonth());
		calendar.gotoDate(start_date);
	});
});

onmount('#jump-to-finish', function(){
	$(this).on('click', function(){
		const finish_date_raw = $('#finished-on-fillin').attr('date');

		if (finish_date_raw === undefined) {
			return;
		}

		const finish_date = new Date(finish_date_raw);

		$('#year-selector').selectpicker('val', finish_date.getFullYear());
		$('#month-selector').selectpicker('val', finish_date.getMonth());
		calendar.gotoDate(finish_date);
	});
});

onmount('#journal-completion-date-submit', function(){
	$(this).on('click', function() {
		const picker_date = $('#journal-completion-date-calendar').datepicker('getDate');
		micro_modal.close('journal-completion-date-modal');
		const fillin_field = $('#journal-completion-date-modal').attr('field');
		$('#journal-completion-date-modal').attr('field', '');

		setLogDateField('#' + fillin_field, picker_date);

		if (fillin_field == "started-on-fillin") {
			createStartEvent(picker_date);
		} else if (fillin_field == "finished-on-fillin") {
			createFinishEvent(picker_date);
			$('#play_toggle_checkbox').prop("checked", true);
		}

		$('#year-selector').selectpicker('val', picker_date.getFullYear());
		$('#month-selector').selectpicker('val', picker_date.getMonth());

		$('#journal-completion-date-calendar').datepicker('destroy');
	});
});

onmount('#started-on-fillin, #finished-on-fillin', function(){
	$(this).on('click', function(){

		const date = $(this).attr('date');
		let existing_event = getEventAtDate(date);

		if (existing_event == null) {
			let start_date = $('#started-on-fillin').attr('date');
			let finish_date = $('#finished-on-fillin').attr('date');

			if (start_date != undefined && start_date.length > 0) {
				start_date = formatFullDate(new Date(start_date), true)
			} else {
				start_date = "1960-01-01"
			}

			if (finish_date != undefined && finish_date.length > 0) {
				finish_date = formatFullDate(new Date(finish_date), true)
			} else {
				finish_date = "1d"
			}

			$('#journal-completion-date-calendar').datepicker({
		    format: "yyyy-mm-dd",
		    minViewMode: 0,
		    maxViewMode: 2,
		    todayBtn: "linked",
		    autoclose: true
			}).on('changeDate', function(e){
				$('#journal-completion-date-input').val(formatFullDate(e.date, true));
			});

			$('#journal-completion-date-calendar').datepicker('setStartDate', start_date);
			$('#journal-completion-date-calendar').datepicker('setEndDate', finish_date);

			let load_date = $(this).attr('date');
			if (load_date) {
				load_date = formatFullDate(new Date(load_date), true);
			} 
			const str_today = formatFullDate(new Date(), true);

			$('#journal-completion-date-calendar').datepicker('update', load_date ? load_date : str_today);
			$('#journal-completion-date-input').val(load_date ? load_date : str_today);
			$('#journal-completion-date-input').attr('min', start_date);
			$('#journal-completion-date-input').attr('max', finish_date == "1d" ? str_today : finish_date);
			$('#journal-completion-date-modal').attr('field', $(this).attr('id'));

			micro_modal.show('journal-completion-date-modal', {
				awaitOpenAnimation: true,
				awaitCloseAnimation: true
			});
		} else {
			calendar.gotoDate(existing_event.start);

			$('#year-selector').selectpicker('val', existing_event.start.getFullYear());
			$('#month-selector').selectpicker('val', existing_event.start.getMonth());

			openPlayDate(existing_event);
			toggleLogView();
		}		
	});
});

onmount('#month-selector, #year-selector', function(){
	$(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
		if (e.type == "changed") {
			calendar.gotoDate(new Date($('#year-selector').val(), $('#month-selector').val()))
		}
	});
});

$(document).on('turbolinks:load', function() {		
	// https://stackoverflow.com/questions/19305821/multiple-modals-overlay
	$('.modal-content').on('show.bs.modal',".datepicker, .datepicker-quick, #journal-completion-date-calendar", function(event) {
	    // prevent datepicker from firing bootstrap modal "show.bs.modal"
	    event.stopPropagation(); 
	});

	$(document).on('show.bs.modal', '.modal', function () {
	    var zIndex = 1040 + (10 * $('.modal:visible').length);
	    $(this).css('z-index', zIndex);
	    setTimeout(function() {
	        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
	    }, 0);
	});
});