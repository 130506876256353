import onmount from 'onmount';
import Cookies from 'js-cookie';
import toastr from 'toastr';
import { debounce } from 'throttle-debounce';
import { hideAll } from 'tippy.js';
import Readmore from 'readmore-js';
import { blConfirmation, hideSidebar } from 'helpers.js';
const tippy = require('tippy.js').default;
import {animateFill} from 'tippy.js';
import micro_modal from 'micromodal';
import noUiSlider from 'nouislider';

toastr.options = {
  "closeButton": false,
  "debug": true,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-bottom-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "3000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

var imagesLoaded = require("imagesloaded");

$(document).on('ready turbolinks:load show.bs.modal', function () { 
    onmount();
    $.ajaxSetup({
        beforeSend: $.rails.CSRFProtection
    });
});

$(document).on('turbolinks:before-cache', function () {
    // Prevents duplicate selector bug from happening
    $(".selectpicker").selectpicker('destroy').addClass('selectpicker');
    hideSidebar();

    onmount.teardown();
    hideAll();
});

window.onmount = onmount;
window.toastr = toastr;

const goty_search = debounce(350, (query, category, year) => {
    $.ajax({
        url: "/goty_search/" + year + "/",
        data: { query: query, category: category}
    });
});

onmount('.short-url', function(){
    $(this).on('click', function(){
        var textArea = document.createElement("textarea");
        // https://stackoverflow.com/a/30810322
        // Place in the top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of the white box if rendered for any reason.
        textArea.style.background = 'transparent';


        $.ajax({
            type: "POST",
            url: "/short-url/",
            success(res) {
                textArea.value = res.short_url;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                toastr.success('URL copied to clipboard.', null, {positionClass: 'toast-top-center'});            
            }
        });
    });
});

onmount('.copyable-text', function(){
    $(this).on('click', function(){
        var textArea = document.createElement("textarea");
        // https://stackoverflow.com/a/30810322
        // Place in the top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of the white box if rendered for any reason.
        textArea.style.background = 'transparent';

        textArea.value = $(this).attr('copy-text');
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        toastr.success('Code copied to clipboard.', null, {positionClass: 'toast-top-center'});
    });
});

onmount('.loading-widget', function(){
    const widget_type = $(this).attr('widget_type');
    let parent_element = $(this).parent();

    $.ajax({
        type: "POST",
        url: "/widget/load/" + widget_type,
        success(res) {
            parent_element.html(res);
        }
    });
});

onmount('#survey-dismiss', function(){
    $(this).on('click', function(){
        $(this).alert('close');
        Cookies.set("survey_alert_dismiss", true, { sameSite: 'strict', expires: 365 });
    });
});

onmount('input[type="radio"][name="feedback"]', function(){
    $(this).on('change', function(){
        $('#feedback-submit').prop('disabled', $('input[type="radio"][name="feedback"]:checked').length == 0);
    });
});

onmount('.feedback-button', function(){
    $(this).on('click', function(){
        const type = $(this).attr('feedback_type');
        const question = $(this).attr('question');
        const is_beta = $(this).attr('is_beta') || "true";

        $('#feedback-question-label').html(question);
        $('#feedback-modal').attr('type', type);
        $('#feedback-modal').attr('is_beta', is_beta);

        if (is_beta === "true") {
            $('#beta-opt-out-text').removeClass('d-none');
        } else {
            $('#beta-opt-out-text').addClass('d-none');
        }
        

         MicroModal.show('feedback-modal', {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
        });
    });
});

onmount('#feedback-submit', function(){
    $(this).on('click', function(){
        const type = $('#feedback-modal').attr('type');
        const is_beta = $('#feedback-modal').attr('is_beta');
        const feedback_general = $('input[type="radio"][name="feedback"]:checked').val();
        const feedback_detail = $('#feedback-modal #feedback_body').val();

        MicroModal.close('feedback-modal');

        $.ajax({
            type: "POST",
            url: "/feedback/submit/",
            data: { type: type, feedback_detail: feedback_detail, feedback_general: feedback_general, is_beta: is_beta },
            success(res) {
                toastr.success('Feedback submitted, thank you!');
                $('#feedback-submit').prop('disabled', true);
                $('#feedback-modal #feedback_body').val("");
                $('input[type="radio"][name="feedback"]').prop('checked', false);
            },
        });
    });
});

onmount('.about-faq-btn', function(){
    $(this).on('click', function(){
        const cat_id = $(this).attr('cat_id');
        $(".about-faq-btn").removeClass('filled');
        $(this).addClass('filled');

        if (cat_id == 'all') {
            $('.faq-cat-section').removeClass('d-none')
        } else {
            $('.faq-cat-section').addClass('d-none');
            $('.faq-cat-section[cat_id="' + cat_id + '"]').removeClass('d-none');
        }
    });
});

onmount('.selectpicker#contact_subject', function(){
    $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
        if (clickedIndex == 7) {
            $('#game-data-warning').removeClass('d-none');
        } else {
            $('#game-data-warning').addClass('d-none');
        }
    });
});

onmount('#daily-tip', function(){
    const daily_tip = Cookies.get('daily_tip');

    if (daily_tip == undefined) {
        $.ajax({
            url: "/api/daily-tip/",
            success(res) {
                Cookies.set('daily_tip', res.tip, { sameSite: 'strict', expires: 1 });
                $('#daily-tip').html(res.tip);
            },
        });
    }
});

onmount('.archive-use-log', function(){
    $(this).on('click', function(){
        const btn = $(this);

        blConfirmation("Choosing this game will permanently delete any game-specific data associated with the other game. Including:<br>* Rating<br>* Total Time Played<br>* Game Statuses<br><br>Your logs, reviews, play sessions, and library entries will all transfer. This is irreversible.<br><br>Do you want to continue?",
            function(){},
            function(){
                $.ajax({
                    type: "PATCH",
                    url: "/user/log/resolve-merge",
                    data: { use_archived_log: btn.attr('use_merge'), log_id: btn.attr('game_log_id') },
                    success(res) {
                        window.location.replace("/settings/archive/");
                    },
                    error(err, textStatus, errorThrown) {
                        toastr.success('Error making log change: ' + errorThrown);
                    }
                });
            },
            "Cancel", "Continue"
        );
    });
});

function loadSelectedComment() {
    const selected = window.location.hash;
    if (selected) {
        $('.comment' + selected).addClass('viewing');
    }
}

function loadReviewLikes() {
    let review_ids = []

    $('.like-heart').each(function(index){
        if($(this).attr('model') == 'review') {
            review_ids.push($(this).attr('model_id'));
        }
    });

    review_ids = $.unique(review_ids);

    if (review_ids.length == 0) {
        return;
    }

    $.ajax({
        type: "POST",
        url: "/api/user/reviews/likes",
        data: { ids: review_ids },
        success(res) {
            $.each(res, function(indx, review_id){
                $('#like_' + review_id + " .like-heart").addClass('liked');
            });
        }
    });
}

function loadQuickAccessBar(given_game_ids) {
    let game_ids = []

    if (given_game_ids == null) {
        $('.quick-access-bar, .game-cover.force-load-log').each(function(index){
            if($(this).attr('game_id')) {
                game_ids.push($(this).attr('game_id'));
            }
        });
    } else {
        game_ids = given_game_ids;
    }

    game_ids = $.unique(game_ids);

    if (game_ids.length == 0) {
        return;
    }

    $.when(
        $.ajax({
            type: "POST",
            url: "/api/user/games/logs",
            data: { ids: game_ids},
            success(res) {
                $.each(res, function(game_id, log){

                    if (log.is_play) {
                        // For quick access play btn
                        $('.quick-access-bar #play-' + game_id).addClass('btn-play-fill').addClass('play-type-color').addClass(log.status);
                        $('.quick-access-bar #play-' + game_id + ' button').attr('play_type', log.status);
                        $('.quick-access-bar #play-' + game_id + ' button').css('color', "var(--back-type-" + log.status + ")")

                        // For game cover played fade
                        $(".game-cover[game_id='" + game_id + "']").addClass('fade-played');

                        // For list entries note button movement
                        $(".list-entry-note-btn[game_id='" + game_id + "']").addClass('note-with-status');

                        // For star ratings field in search results
                        $('.star-rating#rating' + game_id + " input#rating_" + log.rating + "_" + game_id).prop('checked', true);
                    }

                    if (log.is_backlog) {
                        $('.quick-access-bar #backlog-' + game_id).addClass('btn-play-fill');
                    }

                    if (log.is_playing) {
                        $('#more-button-container-' + game_id + ' .playing-btn-container').addClass('btn-play-fill');
                        $('.quick-access-bar #playing-' + game_id).addClass('btn-play-fill');
                    }

                    if (log.is_wishlist) {
                        $('#more-button-container-' + game_id + ' .wishlist-btn-container').addClass('btn-play-fill');
                        $('.quick-access-bar #wishlist-' + game_id).addClass('btn-play-fill');
                    }

                    // For search result ratings
                    const rating = log.rating;
                    if (rating > 0) {
                        $('.star-rating-field #rating_' + rating + '_' + game_id).prop('checked', true);
                        $('.star-rating-field + #delete_rating_' + game_id).removeClass('d-none');
                        $('.star-rating-field + #delete_rating_' + game_id).attr('game_log_id', log.id);
                    }

                    // For journal button in search results
                    $('.journal-button-container#journal-' + game_id + " .journal-btn").html('Edit your log');

                    // Display 'View Logs' button when user has logs for that game
                    $('#more-button-container-' + game_id + ' .quick-logs').removeClass('d-none');

                    // For list entries game status badge
                    if (log.status != null) {
                        $(".game-cover[game_id='" + game_id + "'] .status-overlay").html("<div class='status-bubble play-type-bkg " + log.status + "'></div>" + log.status_title);
                    }
                });
            }
        }),
        $.ajax({
            type: "POST",
            url: "/api/user/games/likes",
            data: { ids: game_ids },
            success(res) {
                $.each(res.liked_game_ids, function(index, game_id){
                    $('#more-button-container-' + game_id + ' .quick-game-like[game_id="' + game_id + '"]').addClass('toggled');
                });
            }
        })
    ).done(function(a1, a2){
        $('.quick-access-bar .more-button').each(function(index){
            const content = $('#more-button-container-' + $(this).attr('game_id'));
            const tippy_instance = tippy($(this)[0]);

            tippy_instance.setProps({
                delay: [50, null],
                offset: [8, 0],
                hideOnClick: true,
                interactive: true,
                interactiveBorder: 15,
                animation: 'shift-away-subtle',
                trigger: 'mouseenter click',
                allowHTML: true,
                placement: 'right-end',
                theme: 'backloggd-quick-access',
                onShown(instance) {
                    onmount();
                }
            });

            tippy_instance.setContent($(content).html());
        });
    });
}

window.loadQuickAccessBar = loadQuickAccessBar;

onmount('#declined-confirm-btn', function(){
    const user_id = $(this).attr('user_id');

    $(this).on('click', function(){
        $('#declined-alert').alert('close');
        
        $.ajax({
          type: "PATCH",
          url: "/users/" + user_id,
          data: { perk_status: null }
        });
    });
});

onmount('body', function(){
    loadQuickAccessBar();
    loadReviewLikes();
    loadSelectedComment();
})

onmount('.btn-backer-beta', function(){
    $(this).on('click', function(){
        if (Cookies.get("beta-disable") == undefined) {
            Cookies.set("beta-disable", true, { sameSite: 'strict', expires: 365 });
        } else {
            Cookies.remove("beta-disable");
        }
        window.location.reload(false);
    });
});

onmount('.selectpicker', function(){
    $(this).selectpicker({
        style: ''
    });

    if ($(this).hasClass('platforms-use-slug')) {
        $(this).children().each(function(index){
            $(this).attr('value', $(this).attr('slug'));
        })
    }

    const selected_val = $(this).attr('selected-value');

    if (selected_val) {
        $(this).selectpicker('val', selected_val.split(','));
    }
});

onmount('.pagy-nav', function(){
    $(".pagy-nav .page.prev, .pagy-nav .page.next, .pagy-nav .page.gap").addClass('d-none d-sm-block');
    $('.pagy-nav .page:not(.d-none):eq(0)').addClass('ml-auto');
    $('.pagy-nav .page:not(.d-none):eq(-1)').addClass('mr-auto');
    if ($('.pagy-nav .page:not(.d-none)').length > 8) {
        $(".pagy-nav .page:not(.d-none):eq(-2), .pagy-nav .page:not(.d-none):eq(1)").addClass('d-none d-sm-block');
    }
});

onmount('#refresh-game-data', function(){
    $(this).on('click', function(){
        $(this).prop('disabled', true);
        $('#refresh-game-data i').addClass('fa-spin')
        $.ajax({
            type: "PATCH",
            url: "/refresh-game-data/",
            data: { game_id: $('#game-page-id').attr('game_id') },
            success(res) {
                window.location.replace("/games/" + res.game_slug + "/");
            },
            error(err, textStatus, errorThrown) {
                $('#refresh-game-data i').removeClass('fa-spin');
                toastr.success('Error refreshing data: ' + errorThrown);
            }
        });
    });   
});

onmount('#user_avatar_btn', function() {
    $(this).on('change', function() {
        const fileName = $(this).val().replace("C:\\fakepath\\","");
        $('#file-selected').html(fileName);
    });
});

onmount(".dropdown-toggle", function() {
    $(this).on('click', function() {
        $(this).toggleClass('active');
    });
});

function changeHolidayLetters() {
    setTimeout(function(){
        $('.holiday-letter').toggleClass('holiday-1');
        $('.holiday-letter').toggleClass('holiday-2');
        changeHolidayLetters();
    }, 850)
}

onmount('.holiday-title', function() {
    changeHolidayLetters();
});

onmount('#goty-toggle-edit', function(){
    $(this).on('click', function(){
        $('.goty-edit-entry').toggleClass('d-none');

        if ($('.goty-edit-entry').hasClass('d-none')) {
            $(this).html('<i class="fad fa-pencil-alt"></i> Edit your entries'); 
        } else {
            $(this).html('<i class="fas fa-check"></i> Done editing'); 
        }
    });
});

onmount('.goty-edit-entry', function(){
    $(this).on('click', function(){
        const category = $(this).attr('category');
        const game_id = $(this).attr('game_id');
        $('#goty-search-modal #goty-category').html(category === "goty" ? "GOTY" : category);
        $('#goty-search-modal #goty-category').attr('slug', category);
        $('#goty-search-modal').attr('game_id', game_id);

        if (game_id) {
            $('#goty-search-modal #goty-remove-entry-container').removeClass('d-none');
        } else {
            $('#goty-search-modal #goty-remove-entry-container').addClass('d-none');
        }

        micro_modal.show('goty-search-modal', {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            disableScroll: true
        });
    });
});

onmount('#goty-results-tabs button', function(){
    $(this).on('click', function(){

        if($(this).hasClass('active')) {
            return;
        }

        $('#goty-results-tabs button').removeClass('active');
        $(this).addClass('active');

        if($('#goty-results-tabs #goty_friends').hasClass('active')) {
            $.ajax({
                type: "GET",
                url: '/render/goty_entries/',
                data: { type: 'friends', year: '2022' },
                success(res) {
                    $('#goty-entries').html(res);
                }
            });
        } else if($('#goty-results-tabs #goty_everyone').hasClass('active')) {
            $.ajax({
                type: "GET",
                url: '/render/goty_entries/',
                data: { type: 'everyone', year: '2022' },
                success(res) {
                    $('#goty-entries').html(res);
                }
            });
        } 
    });
});

onmount("#goty-list-save", function() {
    $(this).on('click', function() {

        let submissions = {};

        $(".goty-entry-data").each(function(index){
            submissions[$(this).attr('category')] = $(this).attr('game_id');
        });

        $.ajax({
            type: "POST",
            url: "/goty/submit/",
            data: { submissions: submissions }
        });
    });
});

onmount('#goty-remove-entry', function(){
    $(this).on('click', function(){
        const category = $('#goty-category').attr('slug');

        $.ajax({
            type: "PUT",
            url: "/goty/update/cover/",
            data: { game_id: null, category: category }
        });

        $.ajax({
            type: "POST",
            url: "/goty/entry/submit/",
            data: { game_id: null, category: category }
        });

        $('#' + category + ' .entry-game-name a h2').html("-");
        $('#' + category + ' .goty-edit-entry').attr('game_id', null);

        micro_modal.close('goty-search-modal');
    });
});

onmount('.goty-entry-select', function() {
    $(this).on('click', function() {
        const category = $('#goty-category').attr('slug');
        const name = $(this).attr('game_name');
        const game_id = $(this).attr('game_id');

        $('#' + category + ' .goty-entry-edit .entry-search-input').val(name);
        $('#' + category + ' .goty-entry-data').attr('game_id', game_id);
        $('#' + category + ' .goty-entry-confirmation').removeClass('hide');
        $('#' + category + ' .game-search-container').html('');
        $('#' + category + ' .game-search-container').addClass('d-none');
        $('#' + category + ' .entry-game-name a h2').html(name);
        $('#' + category + ' .goty-edit-entry').attr('game_id', game_id);

        micro_modal.close('goty-search-modal');

        // Reset search field
        $('.entry-search-input').val('');
        $('#game-search-container').addClass('d-none');
        $('#game-search-container-played').removeClass('d-none');

        $.ajax({
            type: "PUT",
            url: "/goty/update/cover/",
            data: { game_id: game_id, category: category }
        });

        $.ajax({
            type: "POST",
            url: "/goty/entry/submit/",
            data: { game_id: game_id, category: category }
        });
    });
});

onmount(".goty-entry-edit .entry-search-input", function() {
    $(this).on('input', function() {
        const query = $(this).val();
        const category = $(this).attr('category');
        const year = $(this).attr('year');
        if (query.length < 2) {
            $('#game-search-container-played').removeClass('d-none');
            $('#game-search-container').addClass('d-none');
        } else {
            goty_search(query, category, year);
        }
    });
});

onmount('#activity-options input[type="checkbox"]', function() {
    $(this).on('change', function() {
        $('#activity-filter-btns').removeClass('d-none');
    });
});

onmount('#reset-activity-filters', function() {
    $(this).on('click', function(){
        window.location.href = $(this).attr('org_url');
    });
});

onmount('#set-default-activity-filters', function(){
    $(this).on('click', function(){
        let default_filters = "";
        const org_url = $(this).attr('org_url');

        // If all are selected, reset default setting back to null
        if ($("#activity-options input[type='checkbox']:checked").length == $("#activity-options input[type='checkbox']").length) {
            default_filters = ""
        } else {
            $("#activity-options input[type='checkbox']:checked").each(function(index){
                default_filters += $(this).val() + ",";
            });

            default_filters = default_filters.slice(0,-1);
        }

        $.ajax({
            url: '/user/settings/default_activities/',
            method: 'POST',
            data: { activities_default: default_filters },
            success(res) {
                window.location.href = org_url;
            },
            error(err, textStatus, errorThrown) {
                toastr.success('Error saving default settings: ' + errorThrown);
            }
        });
    });
});

onmount('#apply-activity-filters', function() {
    $(this).on('click', function(){

        if($("#activity-options input[type='checkbox']:checked").length == $("#activity-options input[type='checkbox']").length) {
            window.location.href = $(this).attr('org_url') + "all" + "/";
            return;
        }

        let filters = "";

        $("#activity-options input[type='checkbox']:checked").each(function(index){
            filters += $(this).val() + ",";
        });

        if(filters.length < 1) {
            return;
        }

        filters = filters.slice(0,-1);
        window.location.href = $(this).attr('org_url') + filters + "/"
    });
});

onmount('#next_link', function(){
    $(this).on('click', function() {
        $(this).prop('disabled', true);
        $(this).html('<div class="spinner"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>');
        const more_activities_url = $(this).attr('href');
        $(this).attr('href', null);

        if(more_activities_url) {
            $.getScript(more_activities_url, function(data){
                onmount();
            });
        }
    });
});

onmount('#play_toggle_checkbox', function() {
    if(!$(this).is(':checked') || $(this).attr('locked') == "true") {
        $('.played-fields-lock').removeClass('d-none');
    }

    if($(this).attr('locked') != "true") {
        $(this).on('change', function() {
            if(!$(this).is(':checked')) {
                $('.played-fields-lock').removeClass('d-none');
            } else {
                $('.played-fields-lock').addClass('d-none');
            }
        });
    }
});

onmount('.admin-back', function(){
    $(this).on('click', function() {
        window.history.go(-1);
        return false;
    });
});

onmount('#comment-privacy-dropdown .nav-link', function() {
    $(this).on('click', function() {

        const current_privacy = $(this);
        const model_type = $(this).attr('model_type');

        $.ajax({
            type: "PATCH",
            url: '/' + model_type + '/comment/privacy/',
            data: { model_id: $(this).attr('model_id'), privacy: $(this).attr('privacy') },
            success(res) {
                $('#comment-privacy-button-text').html(current_privacy.html());
                $('#comment-privacy-dropdown .nav-link').removeClass('current-sort');
                current_privacy.addClass('current-sort');
                toastr.success('Comment privacy setting updated');
            }
        })
    });
});

onmount('.spoiler-warning button', function(){
    $(this).on('click', function(){
        const review_id = $(this).attr('review_id');

        $('.spoiler-warning[review_id=' + review_id + ']').addClass('d-none');
        $('.review-body[review_id=' + review_id + ']').removeClass('d-none');
        $('.expand-review-col[review_id=' + review_id + ']').removeClass('d-none');
    });
});

onmount('#game-profile, #stats-game-banner', function(){
    $('#main-gradient').removeClass('d-none');
    $('#primary-nav').addClass('hide-border');
});

onmount('#cookie-banner-accept', function(){
    const consent = Cookies.get('ne_cookies_consent');
    if (consent === undefined) {
        $('#cookie-banner').removeClass('d-none');
    }

    $(this).on('click', function() {
        Cookies.set('ne_cookies_consent', true, { sameSite: 'strict', expires: 365 });
        $('#cookie-banner').addClass('d-none');
    });
});

onmount('.cookie-banner-deny', function(){
    $(this).on('click', function() {
        Cookies.set('ne_cookies_consent', false, { sameSite: 'strict', expires: 365 });
        toastr.success('Non-essential cookies disabled');
        $('#cookie-banner').addClass('d-none');
    });
});

onmount(".toggle-fade", function() {
    const fade_played = Cookies.get('fade_played');
    if(fade_played) {
      $(this).addClass('active');
    }
});

onmount('#toggle-fade-played-box', function() {
  $(this).prop('checked', Cookies.get('fade_played'));

  $(this).on('change', function() {
      if(!$(this).is(':checked')) {
          Cookies.remove('fade_played');
      } else {
          Cookies.set('fade_played', $(this).is(':checked'), { sameSite: 'strict', expires: 365 });
      }
      $('.toggle-fade').toggleClass('active');
  });
});

onmount('body', function() {
    const high_contrast = Cookies.get('high_contrast');
    if(high_contrast) {
        $(this).addClass('high-contrast');
    }
});

onmount('#high-contrast-colors', function() {

    $(this).prop('checked', Cookies.get('high_contrast'));

    $(this).on('change', function() {
        if(!$(this).is(':checked')) {
            Cookies.remove('high_contrast');
        } else {
            Cookies.set('high_contrast', $(this).is(':checked'), { sameSite: 'strict', expires: 365 });
        }
        $('body').toggleClass('high-contrast');
    });
});

onmount('.card .quick-access-bar', function(){
    const p_width = $(this).parent().width();

    if(p_width > 143) {
        $(this).addClass('btns-bigger2');
        $(this).width($(this).parent().width() * 0.6);
    } else if(p_width > 130) {
        $(this).addClass('btns-bigger2');
        $(this).width($(this).parent().width() * 0.7);
    } else if(p_width > 115) {
        $(this).addClass('btns-bigger');
        $(this).width($(this).parent().width() * 0.8);
    }
});

onmount('.wishlist-btn-container .btn-play, .wishlist-btn-container .log-type-btn', function(){
    $(this).on('click', function(){
        $(this).prop('disabled', true);
        $(this).parent().toggleClass('btn-play-fill');
        let btn = $(this);
        $.ajax({
            type: 'POST',
            url: '/log/',
            data: { type: 'wishlist', game_id: $(this).attr('game_id'), no_refresh: $(this).hasClass('no_refresh') },
            success(res) {
                btn.prop('disabled', false);
            }
        });
    });
});

onmount('.playing-btn-container .btn-play, .playing-btn-container .log-type-btn', function(){
    $(this).on('click', function(){
        $(this).prop('disabled', true);
        $(this).parent().toggleClass('btn-play-fill');
        let btn = $(this);
        $.ajax({
            type: 'POST',
            url: '/log/',
            data: { type: 'playing', game_id: $(this).attr('game_id') },
            success(res) {
                btn.prop('disabled', false);
            }
        });
    });
});

onmount('.backlog-btn-container .btn-play', function(){
    $(this).on('click', function(){
        $(this).prop('disabled', true);
        $(this).parent().toggleClass('btn-play-fill');
        let btn = $(this);
        $.ajax({
            type: 'POST',
            url: '/log/',
            data: { type: 'backlog', game_id: $(this).attr('game_id') },
            success(res) {
                btn.prop('disabled', false);
            }
        });
    });
});

onmount('.play-btn-container .btn-play', function(){
    $(this).on('click', function(){
        if ($(this).parent().hasClass('btn-play-fill')) {
            const btn = $(this);
            micro_modal.show('quick-play-type-modal', {
                awaitOpenAnimation: true,
                awaitCloseAnimation: true,
                onShow: function() {
                    $('#quick-play-type-modal .play-type-option').removeClass('filled');
                    const currVal = btn.attr('play_type');
                    $('#quick-play-type-modal .play-type-option#' + currVal).addClass("filled");
                    $('#quick-play-type-modal').attr('game_id', btn.attr('game_id'));
                }
            });
        } else {
            $(this).prop('disabled', true);
            $(this).parent().addClass('btn-play-fill');
            let btn = $(this);
            $.ajax({
                type: 'POST',
                url: '/log/',
                data: { type: 'play', game_id: $(this).attr('game_id') },
                success(res) {
                    console.log(res);
                    btn.prop('disabled', false);
                    btn.attr('play_type', res.status);
                    btn.css('color', "var(--back-type-" + res.status + ")")
                }
            });
        }
    });
});

onmount('#quick-play-type-modal .play-type-option', function(){
    $(this).on('click', function(){
        micro_modal.close('quick-play-type-modal');
        const game_id = $('#quick-play-type-modal').attr('game_id');
        $.ajax({
            type: 'PATCH',
            url: '/log/status/',
            data: { game_id: game_id, status_id: $(this).attr('status') },
            success(res) {
               $('.play-btn-container#play-' + game_id + " button").attr('play_type', res.status);
               $('.play-btn-container#play-' + game_id + " button").css('color', "var(--back-type-" + res.status + ")");
               $('.play-btn-container#play-' + game_id + " button").blur();
            }
        });
    });
});

onmount('#quick-play-type-modal #unset-played-btn', function(){
    $(this).on('click', function(){
         micro_modal.close('quick-play-type-modal');
         const game_id = $('#quick-play-type-modal').attr('game_id');
         $('#quick-play-type-modal').attr('game_id', "");
         $.ajax({
            type: 'POST',
            url: '/log/',
            data: { type: 'play', game_id: game_id },
            success(res) {
               $('.play-btn-container#play-' + game_id).removeClass('btn-play-fill');
               $('.play-btn-container#play-' + game_id + " button").css('color', "");
               $('.play-btn-container#play-' + game_id + " button").attr('play_type', '');
               $('.play-btn-container#play-' + game_id + " button").removeClass('play-type-color')
               $('.play-btn-container#play-' + game_id + " button").blur();
            }
        });
    });
});

onmount('#game-played-container #game-played-btn', function(){
    $(this).on('click', function(){
        $(this).prop('disabled', true);
        $('#game-played-container').toggleClass('unplayed');
        let btn = $(this);
        $.ajax({
            type: 'POST',
            url: '/log/',
            data: { type: 'play', game_id: $(this).attr('game_id') },
            success(res) {
                btn.prop('disabled', false);
            }
        });
    });
});

// onmount('.btn-play.btn-played', function(){
//     $(this).on('click', function(){
//         $.ajax({
//             type: 'POST',
//             url: '/autocomplete-modal/' + $(this).attr('game_id')
//         });
//     });
// });

onmount('.star-rating .rate .star-radio', function(){
    $(this).on('click', function(){
        console.log($('#play-' + $(this).attr('game_id') + " .btn-play"));
        $('#play-' + $(this).attr('game_id') + " .btn-play").prop('disabled', true);
    });
});


onmount('#journal-help', function(){
    $($(this)[0]).popover({
        content: "<b>Log:</b> Edit your latest log details here, you can also use the Journal to mark each day you've played for that log.<br><br><b>Game Status:</b> Edit data on a game-wide level. Specifically data that is not tied to single log but the game itself.",
        offset: '40.75%p',
        trigger: 'focus',
        html: true
    });
    $(this).on('click', function(){
        $(this).popover('toggle');
    });
});

onmount('#review-formatting-tooltip', function(){
    $($(this)[0]).popover({
        content: "<u><b>Formatting Markdown</b></u><br><i>sample</i> - *sample*<br><b>sample</b> - **sample**<br><u>sample</u> - _sample_<br><s>sample</s> - ~~sample~~<br><a href='https://www.backloggd.com'>sample</a> - [sample](backloggd.com)",
        offset: '40.75%p',
        trigger: 'focus',
        html: true
    });
    $(this).on('click', function(){
        $(this).popover('toggle');
    });
});

onmount('.like-heart.button-link', function(){
    $(this).on('click', function(){
        const model_id = $(this).attr('model_id');
        const model = $(this).attr('model');
        let likes = parseInt($("[like_counter_text=" + model + "-" + model_id + "]").attr('likes'));
        let like_btns = $('button.like-heart[model_id="' + model_id + '"][model="' + model + '"]');
        like_btns.prop('disabled', true);
        
        if($(this).hasClass('liked')) {
            likes -= 1;
            $.ajax({
                type: 'DELETE',
                url: '/unlike/' + model + "/" + model_id,
                success: function() {
                    like_btns.prop('disabled', false);
                }
            });
        } else {
            likes += 1;
            $.ajax({
                type: 'POST',
                url: '/like/' + model + "/" + model_id,
                success: function() {
                    like_btns.prop('disabled', false);
                }
            });
        }
        like_btns.toggleClass('liked');
        if(likes == 1) {
            $("[like_counter_text=" + model + "-" + model_id + "]").html(likes + " Like");
        } else {
            $("[like_counter_text=" + model + "-" + model_id + "]").html(likes + " Likes");
        }
        $("[like_counter_text=" + model + "-" + model_id + "]").attr("likes", likes);
    });
});

// Add a readmore button for long segments of text
onmount('.readmore-content', function() {
  new Readmore($(this).get(), {
      collapsedHeight: 150,
      speed: 200,
      lessLink: '<a href="#" class="readmore-open"><i class="fa-solid fa-arrow-turn-up"></i> Close</a>',
      moreLink: '<a href="#" class="readmore-closed"><i class="fa-solid fa-arrow-turn-down"></i> Expand</a>',
      beforeToggle: function(trigger, element, expanded) {
          $(element).siblings('.note-background').toggleClass("d-none");
      },
      blockProcessed: function(element, collapsable) {
          if (collapsable) {
            console.log(element);
            $("<div class='readmore-background gradient'></div>").insertAfter(element.nextElementSibling);
          }
      }
  });
});

onmount('#bio-body', function(){
     new Readmore($(this).get(), {
        speed: 200,
        collapsedHeight: 300,
        lessLink: '<a href="#" class="readmore-open"><i class="fa-solid fa-arrow-turn-up"></i> Close</a>',
        moreLink: '<a href="#" class="readmore-closed"><i class="fa-solid fa-arrow-turn-down"></i> Expand</a>',
        beforeToggle: function(trigger, element, expanded) {
            $(element).siblings('.note-background').toggleClass("d-none");
        },
        blockProcessed: function(element, collapsable) {
            if (collapsable) {
                $("<div class='readmore-background gradient'></div>").insertAfter(element.nextElementSibling);
            }
        }
    });
});

onmount('#review-sorting + .dropdown-sort .nav-link', function(){
    $(this).on('click', function(){
        const game_id = $('#game-page-id').attr('game_id');
        const game_slug = $('.game-slug-container').attr('id');
        
        $('#review-sorting .nav-label').html($(this).html());
        $('#review-sorting + .dropdown-sort .nav-link').removeClass('current-sort');
        $(this).addClass('current-sort');

        $.ajax({
            type: 'GET',
            url: '/render/game_reviews',
            data: {sort: $(this).attr('sorting'), game_id: game_id, game_slug: game_slug }
        });
    });
});

onmount('#review-sort-picker', function(){
    $(this).selectpicker();

    $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
        const game_id = $('#game-page-id').attr('game_id');
        const game_slug = $('.game-slug-container').attr('id');
        
        $.ajax({
            type: 'GET',
            url: '/render/game_reviews',
            data: {sort: e.target.value, game_id: game_id, game_slug: game_slug }
        });
    });
});

$(document).on('turbolinks:load', function() {

	imagesLoaded("#artwork-high-res", function() {
        $("#artwork-low-res").addClass("d-none");
    });

    if($('#infinite-scrolling').length > 0) {
        $(window).on('scroll', function() {
            var more_posts_url = $('#infinite-scrolling .next_page').attr('href')
            if(more_posts_url && $(window).scrollTop() > $(document).height() - $(window).height() - 230) {
                    $('.pagination').html('<div class="spinner"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>')
                    $.getScript(more_posts_url)
                return
            }
        });
    }

    (adsbygoogle = window.adsbygoogle || []).push({}); 
});